// config/config.js
const { createClient } = require('@supabase/supabase-js');
const dotenv = require('dotenv');
// Your Supabase URL and public API key

dotenv.config();
const supabaseUrl = 'https://itlvdacekywtetwrpcbq.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Iml0bHZkYWNla3l3dGV0d3JwY2JxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjA0NzI2ODgsImV4cCI6MjAzNjA0ODY4OH0.vdhmNZZWKHr8giEqI-Jng0eqnwLQymOIOS65maNOpjQ';

// Create a Supabase client
const supabase = createClient(supabaseUrl, supabaseKey);


// Export the Supabase client and Firebase Admin
module.exports = {
  supabase
};
