import React, { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { supabase } from '../config/config'; // Your Supabase client setup
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

const ChangePassword = () => {
    const [searchParams] = useSearchParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(false);

    // Extract access token from query parameters
    const [accessToken, setAccessToken] = useState('');

    // useEffect(() => {
    //     const token = searchParams.get('access_token');
    //     console.log(token)
    //     if (!token) {
    //         setErrorMessage('Invalid or expired password reset link.');
    //     } else {
    //         setAccessToken(token);
    //     }
    // }, [searchParams]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate passwords
        if (newPassword !== confirmPassword) {
            setErrorMessage('Passwords do not match.');
            setSuccessMessage('');
            return;
        }

        if (newPassword.length < 8) {
            setErrorMessage('Password must be at least 8 characters long.');
            setSuccessMessage('');
            return;
        }

        setLoading(true);
        setErrorMessage('');
        setSuccessMessage('');

        try {
            // Use the access token to update the password
            const { error } = await supabase.auth.updateUser(
                { password: newPassword },
                { headers: { Authorization: `Bearer ${accessToken}` } }
            );

            if (error) {
                setErrorMessage(error.message || 'Failed to update password.');
                return;
            }

            setSuccessMessage('Password successfully updated! Redirecting to login...');
            setTimeout(() => {
                window.location.href = '/login'; // Redirect to login page
            }, 3000);
        } catch (err) {
            setErrorMessage('An unexpected error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <SEO title="Change Password - Edfrica" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <div className="welcome-area onepage-screen-area d-flex align-items-center justify-content-center">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-12">
                                <div className="site-logo mb-4">
                                    <Link to="/" className="logo-light">
                                        <img
                                            src="https://media.licdn.com/dms/image/v2/D4E0BAQFzJQjyoe8tvA/img-crop_100/img-crop_100/0/1728290313697?e=1736380800&v=beta&t=9y0QbX3GijJTKtnZ6nMpgWmD_Ho43AiaqM_2PcNJkFM"
                                            alt="Edfrica Logo Light"
                                            height={200}
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-8 mx-auto">
                                <div className="content">
                                    <h2 className="title mb-3">Reset Your Password</h2>
                                    <p className="mb-4">Please set your new password below.</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="form-group mb-3">
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="New Password"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group mb-3">
                                            <input
                                                type="password"
                                                className="form-control"
                                                placeholder="Confirm Password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                required
                                            />
                                        </div>
                                        {errorMessage && (
                                            <p className="text-danger">{errorMessage}</p>
                                        )}
                                        {successMessage && (
                                            <p className="text-success">{successMessage}</p>
                                        )}
                                        <button type="submit" className="btn btn-primary btn-block" disabled={loading}>
                                            {loading ? 'Updating...' : 'Update Password'}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <ul className="footer-links list-unstyled d-flex justify-content-center">
                                    <li className="mx-3">
                                        <Link to="/" className="text-decoration-none text-muted">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="mx-3">
                                        <Link to="/contact" className="text-decoration-none text-muted">
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li className="mx-3">
                                        <a
                                            href="https://accounts.edfrica.com"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-decoration-none text-muted"
                                        >
                                            My Account
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ul className="shape-group-8 list-unstyled">
                        <li className="shape shape-2">
                            <img src={`${process.env.PUBLIC_URL}/images/others/bubble-28.png`} alt="Bubble" />
                        </li>
                        <li className="shape shape-3">
                            <img src={`${process.env.PUBLIC_URL}/images/others/line-4.png`} alt="Line" />
                        </li>
                    </ul>
                </div>
            </main>
        </>
    );
};

export default ChangePassword;
